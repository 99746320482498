$font-family: 'Montserrat', sans-serif;
$border-color: #cccccc;
$border-color-light: #d9d9d9;
$border-color-lightest: #e8e8e8;
$bg-light: #fefefe;
$border-radius: rem(4px);
$color-muted: #8c8c8c;

$brand-color-dark: #b10169;
$brand-color: #ec018c;
$primary-background: #fff0f6;
$secondary-color: #1890ff;
$danger-color: #f5222d;
$danger-backround: #fff1f0;
$success-color: #52c41a;
$success-background: #f6ffed;

$link-text-hover-color: #ec018c;

$global-font-size: 14px;
$global-small-font-size: 14px;
$global-line-height: 1.5;
$global-primary-background: $brand-color;

$logo-color: $brand-color;
$logo-hover-color: $brand-color;

$base-link-color: $secondary-color;
$base-body-font-weight: 500;
$base-body-font-family: $font-family;
$base-body-color: #000;
$base-heading-font-weight: 700;
$base-heading-font-family: $font-family;
$base-heading-color: #000;

$navbar-background: #fff;
$navbar-nav-item-font-family: $font-family;
$navbar-nav-item-height: rem(72px);
$navbar-nav-item-font-size: 1rem;
$navbar-nav-item-padding-horizontal: 0;
$navbar-nav-item-color: #000;
$navbar-nav-item-hover-color: #000;

$section-xsmall-padding-vertical: rem(25px);
$section-muted-background: #fafafa;
$section-small-padding-vertical: rem(20px);
$section-large-padding-vertical-m: rem(80px);
$section-large-padding-vertical: rem(40px);

$margin-small-margin: rem(8px);
$margin-margin: rem(20px);
$margin-medium-margin: rem(16px);
$margin-large-margin-l: rem(30px);
$margin-large-margin: rem(26px);
$margin-xlarge-margin: rem(46px);
$margin-xlarge-margin-l: rem(46px);

$search-default-width: 100%;
$search-default-height: rem(48px);
$search-default-background: $bg-light;
$search-default-padding-horizontal: rem(24px);
$search-default-icon-width: rem(50px);
$search-color: #000;

$button-line-height: rem(18px);

$button-default-background: $bg-light;
$button-default-hover-background: $bg-light;
$button-default-active-background: $bg-light;
$button-default-color: #000;
$button-default-hover-color: #000;

$button-primary-background: $brand-color;

$button-padding-horizontal: rem(28px);
$button-font-size: $base-body-font-size;

$button-small-padding-horizontal: rem(20px);
$button-small-line-height: rem(34px);
$button-small-font-size: rem(14px);

$button-large-padding-horizontal: rem(48px);
$button-large-line-height: rem(20px);
$button-large-font-size: rem(18px);

$button-text-color: #000;
$button-text-hover-color: #000;

$grid-small-gutter-horizontal: rem(16px);
$grid-medium-gutter-horizontal: rem(24px);
$grid-large-gutter-horizontal-l: rem(50px);

$label-success-background: $success-background;
$label-success-color: #52c41a;
$label-font-size: rem(12px);
$label-background: #fff;
$label-padding-vertical: rem(2px);
$label-color: #595959;

$heading-xlarge-font-size-l: rem(30px);
$heading-large-font-size: rem(20px);
$heading-large-line-height: 1.3;
$heading-large-font-size-l: rem(24px);
$heading-medium-font-size: rem(20px);
$heading-medium-font-size-l: rem(20px);
$heading-small-font-size-m: rem(14px);
$heading-small-font-size: rem(14px);
$heading-small-line-height: 1.5;
$base-h1-font-size-m: rem(48px);
$base-h2-font-size-m: rem(40px);
$base-h2-font-size: rem(24px);
$base-h1-line-height: 1.2;
$base-h2-line-height: 1.25;

$tab-item-color: #3b3b3b;
$tab-item-hover-color: #3b3b3b;
$tab-item-font-size: rem(16px);
$tab-item-padding-horizontal: rem(12px);
$tab-item-padding-vertical: rem(16px);
$tab-margin-horizontal: rem(34px);
$tab-border-width: 0;

$text-success-color: $success-color;
$text-primary-color: $brand-color;
$text-meta-font-size: rem(11px);
$text-small-font-size: rem(12px);
$text-small-line-height: rem(20px);
$text-large-font-size: rem(16px);
$text-muted-color: $color-muted;
$text-secondary-color: $secondary-color;
$text-emphasis-color: #595959;

$inverse-text-primary-color: $brand-color;

$height-medium-height: 340px;

$badge-border-radius: rem(2px);
$badge-size: rem(32px);
$badge-font-size: rem(20px);
$badge-padding-horizontal: rem(10px);
$badge-background: $success-color;

$width-medium-width: 280px;

$overlay-primary-background: rgba(#262626, 0.5);

$alert-danger-color: $danger-color;
$alert-danger-background: $danger-backround;

$alert-success-color: $success-color;
$alert-success-background: $success-background;

$alert-padding: rem(15px);
$alert-padding: rem(15px);
$alert-padding-right: rem(30px);
$alert-close-top: rem(6px);

$card-default-background: #fff;
$card-default-color: #000;
$card-small-body-padding-vertical: rem(15px);
$card-small-body-padding-horizontal: rem(15px);
$card-small-header-padding-vertical: rem(15px);
$card-small-header-padding-horizontal: rem(15px);
$card-header-padding-vertical-l: rem(15px);
$card-header-padding-horizontal-l: rem(24px);
$card-body-padding-vertical-l: rem(24px);
$card-body-padding-horizontal-l: rem(24px);
$card-footer-padding-vertical-l: rem(15px);
$card-footer-padding-horizontal-l: rem(24px);
$card-small-footer-padding-vertical: rem(15px);
$card-small-footer-padding-horizontal: rem(24px);

$global-small-box-shadow: 0px 4px 6px rgba(#d9d9d9, 0.4);
$global-medium-box-shadow: 0px 10px 10px rgba(#b2b2b2, 0.15);
$global-large-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

$navbar-dropdown-background: #fff;
$navbar-dropdown-padding: rem(16px);
$navbar-dropdown-margin: 0;

$background-secondary-background: #34001f;

$padding-small-padding: rem(15px);
$padding-padding-l: rem(25px);
$padding-large-padding-l: rem(40px);
$padding-large-padding: rem(20px);

$inverse-base-color: #fff;

$alert-primary-background: $primary-background;
$alert-primary-color: $brand-color;

$form-large-padding-horizontal: rem(18px);
$form-large-font-size: 1rem;
$form-color: #000;
$form-focus-color: #000;
$form-icon-width: rem(50px);

$form-danger-color: $danger-color;
$form-success-color: $success-color;

$form-radio-size: rem(16px);
$form-radio-checked-focus-background: $brand-color;
$form-radio-checked-background: $brand-color;
$form-radio-background: #fff;
$form-radio-margin-top: 0;

$inverse-dotnav-item-active-background: #fff;

$border-rounded-border-radius: rem(4px);

$pagination-margin-horizontal: rem(8px);

$pagination-item-active-color: $brand-color;

$internal-form-select-image: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCAzMCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjE0IiBmaWxsPSIjRUMwMThDIiBzdHlsZT0iIj48cmVjdCBpZD0iYmFja2dyb3VuZHJlY3QiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHg9IjAiIHk9IjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0ibm9uZSIgY2xhc3M9InNlbGVjdGVkIiBzdHlsZT0iIi8+PGcgY2xhc3M9ImN1cnJlbnRMYXllciIgc3R5bGU9IiI+PHRpdGxlPkxheWVyIDE8L3RpdGxlPjxwYXRoIGQ9Ik0yOS45OTIgMTAuMTgybC0xNC42MyAxNC42My0xNC42My0xNC42M2EyLjQ5OCAyLjQ5OCAwIDAxMC0zLjUzNSAyLjQ5OCAyLjQ5OCAwIDAxMy41MzUgMEwxNS4zNjEgMTcuNzQgMjYuNDU3IDYuNjQ2YTIuNSAyLjUgMCAxMTMuNTM1IDMuNTM2eiIgaWQ9InN2Z18xIi8+PC9nPjwvc3ZnPg0K';

$accordion-title-font-size: 1rem;
$accordion-title-color: #000;
$accordion-title-hover-color: #000;
$accordion-content-margin-top: 0;
$button-disabled-background: #e5e5e5;

$navbar-nav-item-height: rem(72px);
$iconnav-margin-horizontal: rem(16px);

$modal-z-index: 9999;

$background-muted-background: #fafafa;

$notification-message-color: #595959;
$notification-message-padding: 0px;
$notification-message-font-size: rem(14px);
$notification-message-background: transparent;
$notification-width: auto;
$notification-position: 15px;
