@mixin hook-notification() {
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 2px;
  padding: rem(15px);
  max-width: 580px;
  min-width: 350px;
  border: 1px solid transparent;
  transform: translateX(-50%);
  margin-left: 0;

  @media (max-width: 767px) {
    min-width: 280px;
    left: 50% !important;
    width: calc(100% - 30px) !important;
  }

  &.uk-notification-primary,
  &.uk-notification-success,
  &.uk-notification-danger,
  &.uk-notification-warning {
    box-shadow: none;
    padding: rem(18px) rem(15px);
    border-radius: $border-radius;

    .uk-notification-message {
      color: #000;
    }
  }

  &.uk-notification-primary {
    border: 1px solid $secondary-color;
    background: #e6f7ff;
  }

  &.uk-notification-success {
    border-color: #b7eb8f;
    background: #f6ffed;
  }

  &.uk-notification-danger {
    border-color: #ffa39e;
    background: #fff1f0;
  }

  &.uk-notification-warning {
    border-color: #ffe58f;
    background: #fffbe6;
  }

  h2 {
    font-size: rem(16px);
    font-weight: 500;
  }

  a {
    flex-shrink: 0;
    margin-top: rem(4px);
    display: inline-flex;
  }
}

@mixin hook-navbar-toggle() {
  min-height: 56px;
}

@mixin hook-accordion() {
  padding: 0 !important;

  .uk-open .uk-accordion-title:after {
    transform: translateY(-50%) rotate(0);
  }
}

@mixin hook-accordion-title() {
  position: relative;
  background-color: #f2f2f2;
  padding: rem(16px);
  padding-right: rem(40px);

  &:after {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 12px;
    width: 22px;
    height: 22px;
    background: url('../assets/images/icon-feather-plus.svg') no-repeat center;
    transform: translateY(-50%) rotate(45deg);
    transition: transform 0.2s;
  }
}

@mixin hook-accordion-content() {
  border: 1px solid #f2f2f2;
  padding: rem(16px);

  h3 {
    margin: 0;
    font-size: rem(14px);
    line-height: 1.75;
  }

  p:not(:last-child) {
    margin-bottom: rem(8px);
  }
}

@mixin hook-form-radio() {
  border-radius: rem(2px);
  border: 1px solid $form-radio-border;
  color: transparent;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
  min-width: $form-radio-size;

  &:hover {
    border: 2px solid $form-radio-hover-border;
  }
}

@mixin hook-form-radio-focus() {
  border: 1px solid $form-radio-focus-border;

  &:hover {
    border: 2px solid $form-radio-hover-border;
  }
}

@mixin hook-form-radio-checked-focus() {
  border-color: $form-radio-checked-border;
  background-color: $form-radio-checked-background;
}

@mixin hook-form-danger() {
  border: solid 1px $form-danger-border !important;
}

@mixin hook-form-success() {
  border-color: $success-color;
  color: #000;
}

@mixin hook-form-label() {
  color: #000;
  font-size: rem(12px);
}

@mixin hook-form() {
  border-radius: $border-radius;
  border: solid 1px $gray;
  padding-left: 10px;
  cursor: pointer;
}

@mixin hook-form-focus() {
  border-color: #000;
}

@mixin hook-card-misc() {
}

@mixin hook-cover-misc() {
}

@mixin hook-navbar-container() {
  z-index: 1000;
}

@mixin hook-navbar-nav-item() {
  margin-left: rem(34px);
  line-height: rem(24px);
}

@mixin hook-navbar-nav-item-active() {
  color: #000;
  font-weight: 700;
  border-top: 2px solid transparent;
  border-bottom: 2px solid $brand-color-dark;
}

@mixin hook-search-default-input() {
  border-radius: $border-radius 0 0 $border-radius;
  border: $search-default-border;
  padding-left: rem(48px) !important;
}

@mixin hook-button() {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: rem(50px);
  font-weight: bold;
}

@mixin hook-button-small() {
  height: rem(36px);
  white-space: nowrap;
}

@mixin hook-button-large() {
  height: rem(48px);
}

@mixin hook-button-text() {
  height: auto;
}

@mixin hook-button-default() {
  border-radius: $border-radius;
  border: $button-default-border;
  &:hover,
  &:focus {
    border: $button-default-hover-border;
  }
  &:disabled {
    background-color: $button-default-disabled-background !important;
    color: $button-default-disabled-color !important;
    border: $button-default-disabled-border !important;
  }
}

@mixin hook-button-primary() {
  border-radius: $border-radius;
  font-weight: 700;
  &:disabled {
    background-color: $button-primary-disabled-background !important;
    color: $button-primary-disabled-color !important;
    border: $button-primary-disabled-border !important;
  }
}

@mixin hook-button-link() {
  text-decoration: underline;
  font-weight: 400;
  height: auto;

  img {
    width: 20px;
    height: 20px;
  }
}

@mixin hook-label() {
  border-radius: 3px;
  border: 1px solid $border-color-light;
}

@mixin hook-label-success() {
  border-color: #b7eb8f;
}

@mixin hook-tab-item() {
  font-weight: 400;
}

@mixin hook-tab-item-active() {
  font-weight: 700;
  border-bottom: 4px solid $dark-blue;
}

@mixin hook-overlay-primary() {
  color: #fff;
  font-weight: 400;
  background: rgba(38, 38, 38, 0.1);
}

@mixin hook-alert() {
  border-radius: $border-radius;
  padding-left: rem(24px);
}

@mixin hook-alert-primary() {
  border: 1px solid $brand-color;
}

@mixin hook-alert-danger() {
  border: 1px solid $danger-color;
}

@mixin hook-alert-success() {
  border: 1px solid $success-color;
}

@mixin hook-card-default() {
}

@mixin hook-modal-dialog() {
  border: 1px solid $border-color-light;
  border-radius: $border-radius;
  padding: rem(16px);
}

@mixin hook-navbar-dropdown() {
  border: 1px solid $border-color-light;
  border-radius: $border-radius;
  padding: rem(16px);
}

@mixin hook-modal-close-default() {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid $border-color-light;
  background-color: #fff;
  color: #000;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

@mixin hook-button-misc() {}

@mixin hook-dotnav-misc() {
  .uk-light .uk-dotnav a {
    border-color: #fff;
  }
}

@mixin hook-card() {
  border-radius: rem(8px);
  background-color: #fff;

  @media (max-width: 767px) {
    border-radius: $border-radius;
  }
}

@mixin hook-dropdown() {
  border: 1px solid $border-color-lightest;
  border-radius: $border-radius;
}

@mixin hook-base-hr() {
  margin-top: 24px !important;
  margin-bottom: 24px;
  border-top: 1px solid #e2e2e2;
}
