@import 'functions';
@import 'variablesOld';
@import 'variables';

@import '~uikit/src/scss/variables-theme.scss';
@import '~uikit/src/scss/mixins-theme.scss';

@import 'hooks';

@import '~uikit/src/scss/uikit-theme.scss';
@import 'scrollbar';
@import '~react-placeholder/lib/reactPlaceholder.css';

//.top-line {
//  background-color: #FAFAFA;
//  border-top: 1px solid #E8E8E8;
//  border-bottom: 1px solid #E8E8E8;
//}

//.uk-margin-xsmall-left {
//  margin-left: $margin-xsmall-margin;
//}
//
//.uk-margin-xsmall-right {
//  margin-right: $margin-xsmall-margin;
//}
//
//.uk-margin-xsmall-top {
//  margin-top: $margin-xsmall-margin;
//}
//
//.uk-margin-xsmall-bottom {
//  margin-bottom: $margin-xsmall-margin;
//}

//.uk-margin-xxlarge-bottom {
//  margin-bottom: $margin-xxlarge-margin;
//}
//
//.uk-margin-xxlarge-top {
//  margin-top: $margin-xxlarge-margin;
//}
//
//.uk-margin-xxlarge-right {
//  margin-right: $margin-xxlarge-margin;
//}
//
//.uk-margin-xxlarge-left {
//  margin-left: $margin-xxlarge-margin;
//}

//.block {
//  border: 1px solid $border-color-light;
//}

//.hotel {
//
//  &-side {
//    width: 328px;
//  }
//
//  &-side-gallery {
//    width: 360px;
//    box-sizing: border-box;
//  }
//
//  &-header {
//    position: relative;
//    z-index: 0;
//
//    &:after {
//      content: '';
//      position: absolute;
//      z-index: -1;
//      bottom: 0;
//      left: 0;
//      display: block;
//      width: 100%;
//      border-bottom: 1px solid $border-color-light;
//    }
//  }
//
//  &-bottom {
//    border: 1px solid $border-color-light;
//    border-radius: 0 0 $border-radius $border-radius;
//  }
//
//  &-service {
//    padding-top: 18px;
//    padding-bottom: 13px;
//    border-bottom: 1px solid $border-color-light;
//
//    img {
//      margin-bottom: 12px;
//    }
//  }
//
//  &-promo-text {
//    padding: 14px 32px;
//  }
//
//  &-comment {
//    border-left: 1px solid $border-color-light;
//    padding: 14px 42px;
//
//    &-author {
//      position: relative;
//      padding-top: 15px;
//      margin-top: 15px;
//
//      &:before {
//        content: '';
//        position: absolute;
//        top: 0;
//        left: 50%;
//        display: block;
//        width: 12px;
//        transform: translateX(-50%);
//        border-top: 1px solid $border-color-light;
//      }
//    }
//  }
//
//  &-rating {
//    width: 250px;
//    border-right: 1px solid $border-color-light;
//
//    &-header {
//      padding: 12px 18px;
//      background-color: $success-background;
//    }
//
//    &-items {
//      padding-left: 14px;
//      padding-right: 14px;
//    }
//
//    &-item {
//      padding: 10px 0;
//
//    }
//  }
//}

//.promo-text {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//  padding: 10px 30px;
//  border: 1px solid $border-color-light;
//  border-radius: 1px;
//
//  div {
//    padding-left: 15px;
//    padding-right: 15px;
//  }
//
//  img:last-child {
//    transform: scale(-1, 1)
//  }
//}

//.uk-section {
//
//  &-alert {
//    margin: -1px -1px 0 -1px;
//    border-bottom-left-radius: 0;
//    border-bottom-right-radius: 0;
//  }
//
//  &-body {
//    position: relative;
//  }
//}

//.discount-label {
//  position: absolute;
//  z-index: 1;
//  padding: 12px 36px 12px 12px;
//  background-color: $success-color;
//  color: #fff;
//  font-weight: 400;
//  transform: translateX(21px);
//
//  &:after {
//    content: '';
//    position: absolute;
//    top: 100%;
//    right: 0;
//    border-top: 10px solid #B7EB8F;
//    border-left: 10px solid #b7eb8f;
//    border-right: 10px solid transparent;
//    border-bottom: 10px solid transparent;
//  }
//}

.deal-date {
  padding: 16px 35px 12px;

  &-divider {
    border-top: 1px solid $border-color-light;
    margin-bottom: 2px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.deal-plus {
  margin-top: 74px;
  align-self: center;
}

//.deal-img {
//  max-width: 240px;
//  height: 200px;
//  width: 100%;
//}

.div-fill {
  height: 100%;
  width: 100%;
}

//.deal-total {
//  color: $brand-color;
//
//  &-value {
//    font-size: 30px;
//    font-weight: 700;
//    line-height: 34px;
//
//    .offer & {
//      font-size: 20px;
//      line-height: 25px;
//    }
//  }
//
//  &-small {
//    font-size: 11px;
//    font-weight: 400;
//  }
//
//  &-slash {
//    font-size: 21px;
//    font-weight: 400;
//    line-height: 1;
//  }
//}

//.sticky-footer {
//  position: sticky;
//  bottom: 0;
//}

//.guarantee {
//  display: flex;
//  align-items: center;
//  padding: 6px 20px 6px 12px;
//  border-radius: $border-radius;
//  font-weight: 700;
//
//
//  &-primary {
//    background-color: $danger-background;
//
//  }
//
//  &-large {
//    font-size: 18px;
//    line-height: 1.6;
//    justify-content: center;
//    padding: 32px;
//    border: 1px dashed #FFADD2;
//
//  }
//}

//.uk-self-center {
//  align-self: center;
//}

//.list-icon {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  flex-shrink: 0;
//  width: 30px;
//  height: 30px;
//  margin-right: 6px;
//  color: $brand-color-dark;
//
//  &-bar {
//    position: relative;
//    left: 2px;
//  }
//}
//

//.offer-alert {
//  color: $danger-color;
//  font-size: 12px;
//  line-height: 1;
//  transform: translateY(12px);
//  text-align: center;
//}

//.offer-btn {
//  width: 100%;
//  max-width: 550px;
//  color: #595959 !important;
//  font-size: 16px;
//  line-height: 37px;
//  font-weight: 400;
//}

//.about {
//  p {
//    margin-top: 0;
//    margin-bottom: 1.31em;
//  }
//
//  h2 {
//    margin-bottom: 20px;
//  }
//
//  .uk-width-medium {
//
//    .uk-heading-medium {
//      margin-top: 24px;
//    }
//  }
//}

//.uk-padding-medium {
//  padding: 25px;
//}

//.uk-padding-xsmall {
//  padding: 10px;
//}

//.map {
//  height: 314px;
//}

//}

//.footer {
//  padding-top: 55px;
//  padding-bottom: 10px;
//
//  &-top {
//    border-bottom: 1px solid #6E0041;
//  }
//
//  &-top-text {
//    max-width: 190px;
//  }
//
//  &-main {
//    padding-top: 38px;
//    padding-bottom: 10px;
//  }
//
//  &-side {
//    width: 100%;
//    max-width: 290px;
//  }
//}
//
//.uk-alert-primary {
//  border: 1px solid $brand-color;
//  border-radius: $border-radius;
//  background-color: $danger-background;
//  color: $brand-color;
//  padding: 15px;
//}

.language-modal,
.currency-dropdown {
  width: 100%;
  max-width: 1000px !important;
}

.currency-dropdown {
  display: block;
  right: 0 !important;
  left: auto !important;
  animation: slide-down 0.3s;
}

.uk-checkbox-muted {
  border-color: #c4c4c4;
}

.uk-input:focus {
  border: 1px solid #ec018c;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-20px);
    visibility: hidden;
  }

  to {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}
