$font-family: 'Poppins';
$border-radius: rem(4px);

/* COLORS */
$cornflower: #6a74f6;
$warm-blue: #3f54ea;
$blue-blue: #2642d1;
$dark-blue: #301bc1;
$pear: #cbea59;
$kiwi: #99ea4b;
$pinkish-red: #ef2056;
$accessible-red-small-text: #e7174d;
$pine-green: #05131e;
$gunmetal: #595d60;
$veryDarkGray: #292929;
$black: #000;
$white: #fff;
$very-light-pink: #efefef;
$raven: #72777b;
$gray: #bcbcbc;

/* FONT */
$global-font-size: 14px;
$global-small-font-size: 14px;
$global-line-height: 1.5;

$base-body-font-family: $font-family;
$base-body-font-weight: 400;
$base-body-color: $pine-green;

/* HEADING */
$base-heading-font-weight: 500;
$base-heading-color: $pine-green;
$base-heading-font-family: $font-family;

$base-h5-font-size: rem(15px);
$base-h5-line-height: rem(20px);

$base-h4-font-size: rem(16px);
$base-h4-line-height: rem(20px);

$base-h3-font-size: rem(18px);
$base-h3-line-height: rem(22px);

$base-h2-font-size: rem(20px);
$base-h2-font-size-m: rem(20px);
$base-h2-line-height: normal;

$base-h1-font-size: rem(26px);
$base-h1-font-size-m: rem(26px);
$base-h1-line-height: normal;

$heading-small-font-size: rem(16px);
$heading-small-font-size-m: rem(16px);
$heading-small-line-height: rem(20px);

$heading-medium-font-size: rem(21px);
$heading-medium-font-size-l: rem(21px);
$heading-medium-line-height: rem(19px);

/* MARGIN */
$margin-small-margin: rem(4px);
$margin-margin: rem(8px);
$margin-medium-margin: rem(16px);
$margin-large-margin: rem(25px);
$margin-large-margin-l: rem(25px);
$margin-xlarge-margin: rem(36px);
$margin-xlarge-margin-l: rem(36px);

/* TEXT */
$text-secondary-color: $gunmetal;
$text-emphasis-color: $pine-green;
$text-muted-color: $gray;
$text-primary-color: $warm-blue;
$text-danger-color: $accessible-red-small-text;

$text-small-font-size: rem(13px);
$text-small-line-height: rem(15px);

$text-large-font-size: rem(15px);
$text-large-line-height: rem(15px);

$text-meta-font-size: rem(12px);
$text-meta-line-height: rem(13px);
$text-meta-color: $gunmetal;

/* BUTTON */
$button-large-padding-horizontal: rem(24px);
$button-large-line-height: rem(20px);
$button-large-font-size: rem(18px);

/* BUTTON DEFAULT */
$button-default-background: $white;
$button-default-border: solid 1px $cornflower;
$button-default-color: $pine-green;

$button-default-hover-background: $white;
$button-default-hover-color: $pine-green;
$button-default-hover-border: solid 2px $cornflower;

$button-default-active-background: #e2e4ff;
$button-default-active-color: $pine-green;
$button-default-active-border: solid 2px $cornflower;

$button-default-disabled-background: $white;
$button-default-disabled-color: $raven;
$button-default-disabled-border: solid 1px $gray;

/* BUTTON TEXT */
$button-text-border-width: 0;
$button-text-transform: none;
$button-text-color: $pine-green;
$button-text-hover-color: $pine-green;

/* BUTTON PRIMARY */
$button-primary-background: $cornflower;
$button-primary-color: $white;

$button-primary-hover-background: $warm-blue;
$button-primary-active-background: #3345c6;

$button-primary-disabled-background: $raven;
$button-primary-disabled-color: $white;
$button-primary-disabled-border: none;

/* BUTTON LINK */
$button-link-color: $warm-blue;
$button-link-hover-color: $warm-blue;
$button-link-disabled-color: $gray;

/* LINK */
$link-text-hover-color: $warm-blue;

/* CONTAINER */
$container-padding-horizontal: 30px;
$container-padding-horizontal-s: 30px;
$container-padding-horizontal-m: 30px;
$container-large-max-width: 1380px;

/* NAVBAR */
$navbar-background: $white;
$navbar-nav-item-font-family: $font-family;
$navbar-nav-item-height: rem(24px);
$navbar-nav-item-font-size: rem(14px);
$navbar-nav-item-padding-horizontal: 0;
$navbar-nav-item-color: $gunmetal;
$navbar-nav-item-hover-color: $gunmetal;

/* SEARCH */
$search-default-width: 100%;
$search-default-height: rem(50px);
$search-default-background: $white;
$search-default-padding-horizontal: 0;
$search-default-icon-width: rem(51px);
$search-default-border: solid 1px #e2e2e2;
$search-color: $pine-green;
$search-placeholder-color: $gunmetal;

/* FORMS */
$internal-form-select-image: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMjQgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT5BRjFCQzBFNS01RjVDLTRCQzItOEMzNi1GMzBBODVCMzFBNEQ8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImhvdGVscy1Sb29tMS1waWNrZXItMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwOTguMDAwMDAwLCAtMzczLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMjAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk2Ni4wMDAwMDAsIDE3My4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJkcm9wZG93bi0vLW1pbmktLy1kZWZhdWx0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTg3LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJkcm9wLS8tZG93biIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMyLjAwMDAwMCwgMTMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjAgMCAxNC4yIDAgMTQuMiAxNC4yIDAgMTQuMiI+PC9wb2x5Z29uPgogICAgICAgICAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIgogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjIwMDg3OSwgNy4xMDAwMDApIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTcuMjAwODc5LCAtNy4xMDAwMDApICIKICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgcG9pbnRzPSI2LjAyMzg4NDU4IDcuMSAxMC4yNjAwOTE3IDExLjQ1Njk0MjcgOS4zMTg4OTc5MiAxMi40MjUgNC4xNDE2NjY2NyA3LjEgOS4zMTg4OTc5MiAxLjc3NSAxMC4yNjAwOTE3IDIuNzQzMDU3MzQiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==';

$form-danger-color: inherit;
$form-danger-border: $pinkish-red;

/* PADDING */
$padding-padding: rem(16px);
$padding-padding-l: rem(16px);
$padding-large-padding: rem(24px);
$padding-large-padding-l: rem(24px);

/* GRID */
$grid-medium-gutter-horizontal: rem(30px);

/* CHECKBOX */
$form-radio-size: rem(20px);
$form-radio-background: $white;
$form-radio-checked-focus-background: $form-radio-background;
$form-radio-checked-background: $cornflower;
$form-radio-border-width: 1px;
$form-radio-border: #979797;
$form-radio-hover-border: $cornflower;
$form-radio-focus-border: $form-radio-border;
$form-radio-checked-border: #979797;
$form-radio-margin-top: 0;
