.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}

.rcs-custom-scroll .rcs-outer-container {
  position: relative;
  overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.rcs-inner-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-inner-container.rcs-content-scrolled:after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  height: 3px;
  object-fit: contain;
  opacity: 0.11;
  border-radius: 9px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 4px;
  right: 4px;
  opacity: 1;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    width: 8px;
  }
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}

.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 12px;
  background-color: rgba(188, 188, 188, 0.7);
  border-radius: 2px;

  &:hover,
  &:focus,
  &:active {
    border-radius: 4px;
  }
}
